<script>
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page'
import BaseButton from '/~/components/base/button/base-button'
import BaseCard from '/~/components/base/card/base-card.vue'
import BaseState from '/~/components/base/state/base-state.vue'
import { useLoyaltyCards } from '/~/composables/loyalty-cards'
import LoyaltyCard from './components/loyalty-card.vue'

export default {
  name: 'loyalty-cards',
  components: {
    LoyaltyCard,
    BaseButton,
    BaseState,
    BaseAsidePage,
    BaseCard,
  },
  props: {
    to: {
      type: String,
      default: 'menu-modal',
    },
    close: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const {
      loyaltyCards,
      isLoyaltyCardsLoading,
      isLoyaltyCardsEmpty,
      loyaltyCardsLoadingError,
      getLoyaltyCards,
    } = useLoyaltyCards()

    getLoyaltyCards()

    return {
      loyaltyCards,
      isLoyaltyCardsLoading,
      isLoyaltyCardsEmpty,
      loyaltyCardsLoadingError,
    }
  },
  computed: {
    showAddButton() {
      return !this.isLoyaltyCardsLoading
    },
    pageAction() {
      if (!this.showAddButton) {
        return null
      }

      return this.onCreate
    },
  },
  methods: {
    onCreate() {
      this.$emit('add')
    },
    onClick(card) {
      this.$emit('open', card.id)
    },
  },
}
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    title="Loyalty Cards"
    :action="pageAction"
  >
    <base-state
      v-if="isLoyaltyCardsEmpty"
      title="Add Loyalty Cards"
      image="/empty/empty-loyalty-cards.svg"
      class="min-h-full"
    >
      <p>
        Securely store and manage your favourite loyalty and membership cards in
        one central location.
      </p>

      <template #footer>
        <base-button @click="onCreate">Add Loyalty Card</base-button>
      </template>
    </base-state>

    <div class="grid grid-cols-2 gap-5">
      <template v-if="isLoyaltyCardsLoading">
        <base-card class="animate-pulse rounded-xl bg-divider" />
        <base-card class="animate-pulse rounded-xl bg-divider" />
      </template>

      <div
        v-else-if="loyaltyCardsLoadingError"
        class="col-span-2 text-center font-bold text-fg-error"
      >
        {{ loyaltyCardsLoadingError }}
      </div>

      <template v-else>
        <loyalty-card
          v-for="(card, idx) in loyaltyCards"
          :key="idx"
          :card="card"
          class="cursor-pointer transition duration-200 hover:shadow-lg"
          @click="onClick(card)"
        />
      </template>
    </div>
  </base-aside-page>
</template>
